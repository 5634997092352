import {Modal, Row, Col} from 'react-bootstrap';

import	foto1	from './../../../../images/geleria/atelier/DSC00250.jpg';
import	foto2	from './../../../../images/geleria/atelier/DSC00524.jpg';
import	foto3	from './../../../../images/geleria/atelier/DSC00526.jpg';
import	foto4	from './../../../../images/geleria/atelier/DSC00527.jpg';
import	foto5	from './../../../../images/geleria/atelier/DSC00530.jpg';
import	foto6	from './../../../../images/geleria/atelier/DSC00535.jpg';
import	foto7	from './../../../../images/geleria/atelier/DSC00592.jpg';
import	foto8	from './../../../../images/geleria/atelier/DSC00599.jpg';
import	foto9	from './../../../../images/geleria/atelier/DSC00602.jpg';
import	foto10	from './../../../../images/geleria/atelier/DSC00624.jpg';
import	foto11	from './../../../../images/geleria/atelier/DSC00649.jpg';
import	foto12	from './../../../../images/geleria/atelier/DSC00735.jpg';
import	foto13	from './../../../../images/geleria/atelier/DSC00736.jpg';
import	foto14	from './../../../../images/geleria/atelier/DSC00751.jpg';
import	foto15	from './../../../../images/geleria/atelier/DSC00782.jpg';
import	foto16	from './../../../../images/geleria/atelier/DSC00785.jpg';
import	foto17	from './../../../../images/geleria/atelier/DSC00786.jpg';
import	foto18	from './../../../../images/geleria/atelier/DSC01027.jpg';
import	foto19	from './../../../../images/geleria/atelier/DSC01029.jpg';
import	foto20	from './../../../../images/geleria/atelier/DSC01030.jpg';
import	foto21	from './../../../../images/geleria/atelier/DSC01032.jpg';
import	foto22	from './../../../../images/geleria/atelier/DSC01649.jpg';
import	foto23	from './../../../../images/geleria/atelier/DSC01681.jpg';


function ModalAtelier({showModal, funcFechar}){

    const Galeria = [
        { src:	foto1	},
        { src:	foto2	},
        { src:	foto3	},
        { src:	foto4	},
        { src:	foto5	},
        { src:	foto6	},
        { src:	foto7	},
        { src:	foto8	},
        { src:	foto9	},
        { src:	foto10	},
        { src:	foto11	},
        { src:	foto12	},
        { src:	foto13	},
        { src:	foto14	},
        { src:	foto15	},
        { src:	foto16	},
        { src:	foto17	},
        { src:	foto18	},
        { src:	foto19	},
        { src:	foto20	},
        { src:	foto21	},
        { src:	foto22	},
        { src:	foto23	}
    ];

    function fecharModal(){
        funcFechar();
    }

    return(
        <Modal show={showModal} onHide={fecharModal} fullscreen={true} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header className='fundo-header-modal' closeButton>
                <Modal.Title>Atelier</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                {
                    Galeria.map((x, index) => {
                        return(
                            <Col md={4} lg={4} sm={6} key={index}>
                                <img src={x.src} alt="Atelier Celso Izidoro" className='imgGaleria'/>
                            </Col>
                        )
                    })
                }
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ModalAtelier;