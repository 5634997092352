import {Modal, Row, Col} from 'react-bootstrap';

function ModalABuscaDoInconsciente({showModal, funcFechar}){

    function fecharModal(){
        funcFechar();
    }

    return(
        <Modal show={showModal} onHide={fecharModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header className='fundo-header-modal' closeButton>
                <Modal.Title>CELSO IZIDORO E A BUSCA DO INCONSCIENTE</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <p>Abordagem da metodologia histórica crítica com o intuito de tecer relações vida e obra do artista a im de compreendermos que, por mais que existam mudanças, os verdadeiros valores devem permanecer.</p>

                        <h2>CELSO IZIDORO E A BUSCA DO INCONSCIENTE</h2>

                        <p>Celso Izidoro nasceu em Ituporanga, no interior de Santa Catarina (01/07/54) e está radicado no Paraná desde 1964.</p>

                        <p>Já participou e foi premiado em inúmeras exposições individuais e coletivas, realizadas em diversos estados brasileiros e no exterior.</p>

                        <p>Suas obras estão espalhadas em diversas galerias e museus e dispostos em acervos particulares de todo o país, assim como de Portugal, Inglaterra, Alemanha, Estados Unidos, Japão, Áustria, Argentina e Canadá.</p>

                        <p>Autodidata, nunca freqüentou escola de artes. Começou rabiscando as paredes de casa e da escola com carvão.Logo de início mostrou–se um pintor abstrato, passan-do pelo cubismo e, mais tarde pelo impressionismo. Paralelamente revelou–se excelente retratista e professor de pintura, o que lhe possibilitou viver da arte em Curitiba, como conta a Nery Batista.</p>

                        <p>A incansável busca do artista em alorar o inconsciente tem sido uma constante, notando–se, assim, em todas as fases, uma grande diversidade de expressão.</p>

                        <p>Apesar de toda esta busca por uma linguagem própria, atualmente Celso Izidoro retorna à abstração, só que com total controle do inconsciente.</p>

                        <p>“O artista é livre para brincar com todas as possibilidades...” segundo Celso Izidoro, “é errado pensar que o artista precisa fazer só um tipo de pintura. Isso é a sociedade e os críticos que exigem.”</p>

                        <p>Inicialmente, ansiava suprir uma carência tentando buscar algo novo na pintura. Brincando com tintas molhadas, carochas e minhocas faziam em suas telas efeitos interessantes. Não satisfeito, Izidoro queria ter o controle sobre essas manchas.</p>

                        <p>Comenta que tinha vergonha de mostrar seus quadros, com medo de não ser compreendido, evidenciado assim o motivo pelo qual parte para novas experiências.</p>

                        <p>Começa então a fazer retratos. Ansioso, queria dominar a realidade objetiva, segundo o artista, “seria o subconsciente fazendo algo matemático sem sofrer”. Para tanto criava o ambiente, de acordo com o modelo a ser retratado, móveis, roupas, incensos. Houve um momento em que o fundo do quadro passou a superar o objetivo inicial, pois naquele instante começava a penetrar no inconsciente, ultrapassando barreiras. </p>
                        <p>Surge então uma nova fase.</p>

                        <p>A fase Santa, o surrealismo, a simbologia, uma sendo complementada pela outra. Foi com os “Girassóis”, série de trabalhos surrealistas que o artista desenvolveu em meados de 1985, que começou a se preocupar com as cores.</p>

                        <p>Dominado principalmente pelas misturas de prata e roxo, que o fazia regressar no tempo, e a sabedoria emanada pelo dourado, parte então para a Simbologia.</p>

                        <p>Na trajetória de Izidoro percebe–se com freqüência um elemento místico, seja ele implícito ou não em sua obra.</p>

                        <p>Neste estágio não era possível entender o seu trabalho pois, segundo ele, estava dando “um mergulho no inconsciente”.</p>

                        <p>Para Celso Izidoro, todas as suas fases foram um aprendizado e sempre estarão contidas em sua obra.</p>

                        <p>De volta ao abstrato, as cores primitivas são predominantes nas suas atuais obras em acrílico sobre tela.“O artista é uma vanguarda de si mesmo”.</p>

                        <p>| Simone Falkenbach Von Linsingen |<br/>
                        Escola De Música E Belas Artes Do Paraná<br/>
                        (EMBAP) Curitiba, 2003.</p>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ModalABuscaDoInconsciente;