export default function SectionFooter(){
    return(
        <footer className="footer">
            <div className="container footer-container">
                <div className="footer-text">
                    <p></p>
                    <div className="footer-copy font-alt">
                        <a href="#top" >&copy; Celso Izidoro - 2017</a>
                    </div>
                    <div className="footer-made">
                        Desenvolvido por <a href="http://www.simonsystem.com.br" target="_blank" rel="noreferrer">Simon System</a>.<br/>
                    </div>
                    <p></p>
                </div>
            </div>
            <div className="local-scroll">
                <a href="#top" className="link-to-top"><i className="fa fa-caret-up"></i></a>
            </div>
        </footer>
    )
}