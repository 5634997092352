import {Modal, Row, Col} from 'react-bootstrap';

function ModalGirassol({showModal, funcFechar}){

    function fecharModal(){
        funcFechar();
    }

    return(
        <Modal show={showModal} onHide={fecharModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header className='fundo-header-modal' closeButton>
                <Modal.Title>GIRASOL</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <p>Como uma explosão chegaram os girassóis</p>
                        <p>A simplicidade da flor me conquistou</p>
                        <p>Girassol</p>
                        <p>A paz do teu silêncio eletriza–me</p>
                        <p>Tua realidade </p>
                        <p>Me basta</p>
                        <p>Fascina–me</p>
                        <p>Mas</p>
                        <p>Busquei em ti a metafísica que me faz transbordar</p>
                        <p>Minha vitalidade aliada à tua essência </p>
                        <p>Permite–me sentir o sol que te faz girar</p>
                        <p>Arrogância, cores quentes e formas primitivas</p>
                        <p>Faz–me pensar a aura dos símbolos que sempre busquei</p>
                        <p>Ao sentir os raios do sol atraídos pelas pétalas que</p>
                        <p>emolduram teu colo</p>
                        <p>Direcionados ao encontro do teu núcleo</p>
                        <p>Explode em êxtases</p>
                        <p><br/><br/></p>
                        <p>Celso Izidoro, 1985.</p>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ModalGirassol;