import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";


import imgCapa2 from './../../images/fwi/bg2.jpg';

function SectionTestemunhos(){

    return(
        <div className="page-section bg-dark bg-dark-alfa-50" style={{ backgroundImage: `url(${imgCapa2})` }}>
           <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                pagination={{
                    clickable: true,
                }}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className="row">
                        <div className="col-md-12 col-md-offset-2 align-center">
                            <div className="section-icon">
                                <span className="icon-quote"></span>
                            </div>
                            <h3 className="small-title font-alt">O quê as pessoas dizem?</h3>
                            <blockquote className="testimonial white">
                                <p>
                                    Izidoro transborda misticismo
                                </p>
                                <footer className="testimonial-author">
                                    Maria Helena de Morais
                                </footer>
                            </blockquote>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="row">
                        <div className="col-md-12 col-md-offset-2 align-center">
                            <div className="section-icon">
                                <span className="icon-quote"></span>
                            </div>
                            <h3 className="small-title font-alt">O quê as pessoas dizem?</h3>
                            <blockquote className="testimonial white">
                                <p>
                                    Um novo tempo
                                </p>
                                <footer className="testimonial-author">
                                    Rezende de Morais
                                </footer>
                            </blockquote>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="row">
                        <div className="col-md-12 col-md-offset-2 align-center">
                            <div className="section-icon">
                                <span className="icon-quote"></span>
                            </div>
                            <h3 className="small-title font-alt">O quê as pessoas dizem?</h3>
                            <blockquote className="testimonial white">
                                <p>
                                    Psicografa a força da eternidade
                                </p>
                                <footer className="testimonial-author">
                                    Gilmar Silva
                                </footer>
                            </blockquote>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="row">
                        <div className="col-md-12 col-md-offset-2 align-center">
                            <div className="section-icon">
                                <span className="icon-quote"></span>
                            </div>
                            <h3 className="small-title font-alt">O quê as pessoas dizem?</h3>
                            <blockquote className="testimonial white">
                                <p>
                                    Parece apalpar algo desconhecido, logo que tem a haver com os tempos remotos
                                </p>
                                <footer className="testimonial-author">
                                    Aurélio Benitez
                                </footer>
                            </blockquote>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    )
}
export default SectionTestemunhos;