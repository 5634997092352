import {Modal, Row, Col} from 'react-bootstrap';

function ModalAVidaNaVelocidadeDaLuz({showModal, funcFechar}){

    function fecharModal(){
        funcFechar();
    }

    return(
        <Modal show={showModal} onHide={fecharModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header className='fundo-header-modal' closeButton>
                <Modal.Title>A VIDA NA VELOCIDADE DA LUZ</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <p>Disse o poeta que a maior ilusão é ver as coisas como elas são. Pois o que reconhecemos “real” é apenas uma das possíveis leituras do mundo onde estamos mergulhados – e a mais supericial delas. Muito raramente temos peito para romper essa frágil casca e quase sempre nos entregamos gostosamente ao estado de miopia voluntária. Mas os nossos temores diante do abismo não mudam a natureza das coisas. E a realidade é que por detrás e por dentro de tudo que parece sólido existe um turbilhão de forças, uma explosão de signiicados, uma galáxia de símbolos em movimento vertiginoso – um mundo desenhado à luz. E, entre esse mundo e nós, os olhos do artista convidando a duas viagens simultâneas: para fora, rumo às estrelas, de onde provêm todas as cores; para dentro, em direção a nós mesmos, com nossas luzes e sombras, pelos espaços interiores que precisamos conhecer melhor.</p>

                        <p>Entre o mistério e o desconhecido, não existe caminho fácil. Mas vale a pena pagar o preço, pois é ele que dá signiicado à nossa peregrinação pelo planeta. A obra de Celso Izidoro é um estímulo vigoroso para quem tem a coragem de dar esse passo fundamental. Passo além dos cinco sentidos, em direção a uma compreensão melhor das dores e maravilhas do existir. Passo que revela novas cores e acrescenta outro sentido à aventura da vida. Passo que rompe com nossa ilusão de ótica. Duplo salto mortal à velocidade da luz, para quem está disposto a tirar véus. E desvendar a vertigem.</p>

                        <p>| Roberto Prado |<br />
                        Poeta, escritor, jornalista. <br />
                        Janeiro de 2006.</p>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ModalAVidaNaVelocidadeDaLuz;