import {Modal, Row, Col} from 'react-bootstrap';

import	foto1	from './../../../../images/geleria/telasdisponiveis/DSC01038.jpg';
import	foto2	from './../../../../images/geleria/telasdisponiveis/DSC01050.jpg';
import	foto3	from './../../../../images/geleria/telasdisponiveis/DSC01052.jpg';
import	foto4	from './../../../../images/geleria/telasdisponiveis/DSC01053.jpg';
import	foto5	from './../../../../images/geleria/telasdisponiveis/DSC01054.jpg';
import	foto6	from './../../../../images/geleria/telasdisponiveis/DSC01055.jpg';
import	foto7	from './../../../../images/geleria/telasdisponiveis/DSC01056.jpg';
import	foto8	from './../../../../images/geleria/telasdisponiveis/DSC01057.jpg';
import	foto9	from './../../../../images/geleria/telasdisponiveis/DSC01058.jpg';
import	foto10	from './../../../../images/geleria/telasdisponiveis/DSC01059.jpg';
import	foto11	from './../../../../images/geleria/telasdisponiveis/DSC01060.jpg';
import	foto12	from './../../../../images/geleria/telasdisponiveis/DSC01061.jpg';
import	foto13	from './../../../../images/geleria/telasdisponiveis/DSC01063.jpg';
import	foto14	from './../../../../images/geleria/telasdisponiveis/DSC01064.jpg';
import	foto15	from './../../../../images/geleria/telasdisponiveis/DSC01065.jpg';
import	foto16	from './../../../../images/geleria/telasdisponiveis/DSC01066.jpg';
import	foto17	from './../../../../images/geleria/telasdisponiveis/DSC01067.jpg';
import	foto18	from './../../../../images/geleria/telasdisponiveis/DSC01074.jpg';
import	foto19	from './../../../../images/geleria/telasdisponiveis/DSC01075.jpg';
import	foto20	from './../../../../images/geleria/telasdisponiveis/DSC01076.jpg';
import	foto21	from './../../../../images/geleria/telasdisponiveis/DSC01077.jpg';
import	foto22	from './../../../../images/geleria/telasdisponiveis/DSC01078.jpg';
import	foto23	from './../../../../images/geleria/telasdisponiveis/DSC01079.jpg';
import	foto24	from './../../../../images/geleria/telasdisponiveis/DSC01080.jpg';
import	foto25	from './../../../../images/geleria/telasdisponiveis/DSC01082.jpg';
import	foto26	from './../../../../images/geleria/telasdisponiveis/DSC01083.jpg';
import	foto27	from './../../../../images/geleria/telasdisponiveis/DSC01084.jpg';
import	foto28	from './../../../../images/geleria/telasdisponiveis/DSC01085.jpg';
import	foto29	from './../../../../images/geleria/telasdisponiveis/tela01.jpg';
import	foto30	from './../../../../images/geleria/telasdisponiveis/tela02.jpg';
import	foto31	from './../../../../images/geleria/telasdisponiveis/tela03.jpg';
import	foto32	from './../../../../images/geleria/telasdisponiveis/tela04.jpg';
import	foto33	from './../../../../images/geleria/telasdisponiveis/tela05.jpg';
import	foto34	from './../../../../images/geleria/telasdisponiveis/tela06.jpg';
import	foto35	from './../../../../images/geleria/telasdisponiveis/tela07.jpg';
import	foto36	from './../../../../images/geleria/telasdisponiveis/tela08.jpg';
import	foto37	from './../../../../images/geleria/telasdisponiveis/tela09.jpg';
import	foto38	from './../../../../images/geleria/telasdisponiveis/tela10.jpg';
import	foto39	from './../../../../images/geleria/telasdisponiveis/tela11.jpg';
import	foto40	from './../../../../images/geleria/telasdisponiveis/tela12.jpg';
import	foto41	from './../../../../images/geleria/telasdisponiveis/tela13.jpg';
import	foto42	from './../../../../images/geleria/telasdisponiveis/tela14.jpg';
import	foto43	from './../../../../images/geleria/telasdisponiveis/tela15.jpg';
import	foto44	from './../../../../images/geleria/telasdisponiveis/tela16.jpg';
import	foto45	from './../../../../images/geleria/telasdisponiveis/tela17.jpg';
import	foto46	from './../../../../images/geleria/telasdisponiveis/tela18.jpg';
import	foto47	from './../../../../images/geleria/telasdisponiveis/tela19.jpg';
import	foto48	from './../../../../images/geleria/telasdisponiveis/tela21.jpg';
import	foto49	from './../../../../images/geleria/telasdisponiveis/tela22.jpg';
import	foto50	from './../../../../images/geleria/telasdisponiveis/tela23.jpg';
import	foto51	from './../../../../images/geleria/telasdisponiveis/tela24.jpg';
import	foto52	from './../../../../images/geleria/telasdisponiveis/tela25.jpg';


function ModalTelasDisponiveis({showModal, funcFechar}){

    const Galeria = [
        { src:	foto1	},
        { src:	foto2	},
        { src:	foto3	},
        { src:	foto4	},
        { src:	foto5	},
        { src:	foto6	},
        { src:	foto7	},
        { src:	foto8	},
        { src:	foto9	},
        { src:	foto10	},
        { src:	foto11	},
        { src:	foto12	},
        { src:	foto13	},
        { src:	foto14	},
        { src:	foto15	},
        { src:	foto16	},
        { src:	foto17	},
        { src:	foto18	},
        { src:	foto19	},
        { src:	foto20	},
        { src:	foto21	},
        { src:	foto22	},
        { src:	foto23	},
        { src:	foto24	},
        { src:	foto25	},
        { src:	foto26	},
        { src:	foto27	},
        { src:	foto28	},
        { src:	foto29	},
        { src:	foto30	},
        { src:	foto31	},
        { src:	foto32	},
        { src:	foto33	},
        { src:	foto34	},
        { src:	foto35	},
        { src:	foto36	},
        { src:	foto37	},
        { src:	foto38	},
        { src:	foto39	},
        { src:	foto40	},
        { src:	foto41	},
        { src:	foto42	},
        { src:	foto43	},
        { src:	foto44	},
        { src:	foto45	},
        { src:	foto46	},
        { src:	foto47	},
        { src:	foto48	},
        { src:	foto49	},
        { src:	foto50	},
        { src:	foto51	},
        { src:	foto52	},
    ];

    function fecharModal(){
        funcFechar();
    }

    return(
        <Modal show={showModal} onHide={fecharModal} fullscreen={true} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header className='fundo-header-modal' closeButton>
                <Modal.Title>Telas Disponíveis</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                {
                    Galeria.map((x, index) => {
                        return(
                            <Col md={4} lg={4} sm={6} key={index}>
                                <img src={x.src} alt="Celso Izidoro - Telas Disponíveis" className='imgGaleria'/>
                            </Col>
                        )
                    })
                }
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ModalTelasDisponiveis;