import {Modal, Row, Col} from 'react-bootstrap';

function ModalReflexoes({showModal, funcFechar}){

    function fecharModal(){
        funcFechar();
    }

    return(
        <Modal show={showModal} onHide={fecharModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header className='fundo-header-modal' closeButton>
                <Modal.Title>REFLEXÕES</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <p>
                        "Izidoro transborda misticismo"<br/>
                        |Maria Helena de Morais|
                        </p>

                        <p>
                        "Um novo tempo"<br/>
                        |Rezende de Morais|
                        </p>

                        <p>
                        "Psicografa a força da eternidade"<br/>
                        |Gilmar Silva|
                        </p>

                        <p>
                        "Parece apalpar algo desconhecido, logo que tem a haver com os tempos remotos"<br/>
                        |Aurélio Benitez|
                        </p>

                        <p>
                        " O que parece não importa, o que importa é o que importa"<br/>
                        |Emerson de Castro Veloso|
                        </p>

                        <p>
                        "O tempo observa a própria superação "<br/>
                        |Edson Soares|
                        </p>

                        <p>
                        "Parecem transmitir mensagens profundas, convidando-nos a lê-las"<br/>
                        |Fernando Carneiro|
                        </p>

                        <p>
                        "Hóstia dourada"<br/>
                        |Jofre Gineste|
                        </p>

                        <p>
                        "São sóis, galáxias, espirais, sistemas excêntricos, explodindo vida transbordante"<br/>
                        |Wilmar do Nascimento|
                        </p>

                        <p>
                        "Além da emoção"<br/>
                        |Antônio Macedo|
                        </p>

                        <p>
                        "Sabedoria milenar indisfarçável que o impulsiona a espargir luz"<br/>
                        |Edna Coquemala|
                        </p>

                        <p>
                        "Instigante"<br/>
                        |Harry Laus|
                        </p>

                        <p>
                        "Mudo de Celso Izidoro, ao qual não se deve elogios, apenas o silêncio, porque é esse momento que podemos contemplar a sensibilidade e inspiração. A arte pela arte"<br/>
                        |João José da Conceição Neto|
                        </p>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ModalReflexoes;