export default function NavBar(){
    return (
        <nav className="main-nav dark transparent stick-fixed">
            <div className="full-wrapper relative clearfix">
                <div className="mobile-nav">
                    <i className="fa fa-bars"></i>
                </div>
                <div className="inner-nav desktop-nav">
                    <ul className="clearlist scroll-nav local-scroll">
                        <li className="active"><a href="#home">Página Inicial</a></li>
                        <li><a href="#sobre">Sobre Celso Izidoro</a></li>
                        <li><a href="#biografia">Biografia</a></li>
                        <li><a href="#livro">Livro</a></li>
                        <li><a href="#galeria">Galeria</a></li>
                        <li><a href="#contato">Contato</a></li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}