import React, { useState } from 'react';
import {Button} from 'react-bootstrap';
import ModalCurriculum from '../Modal/Biografia/Curriculum';
import ModalOQueAbstraAAbstracao from '../Modal/Biografia/OQueAbstraiAAbstracao';
import ModalEmocoesVirgensEmPinturasAbstratas from '../Modal/Biografia/EmocoesVirgensEmPinturasAbstratas'
import ModalABuscaDoInconsciente from '../Modal/Biografia/ABuscaDoInconsciente'
import ModalGirassol from '../Modal/Biografia/Girassol'
import ModalRelfexoes from '../Modal/Biografia/Reflexoes'
import ModalAVidaNaVelocidadeDaLuz from '../Modal/Biografia/AVidaNaVelocidadeDaLuz';

export default function SectionBiografia(){

    const [showModalOQueAbstraiAAbstracao, setShowModalOQueAbstraiAAbstracao] = useState(false);
    const [showModalCurriculum, setShowModalCurriculum] = useState(false);
    const [showModalEmocoesVirgensEmPinturasAbstratas, setShowModalEmocoesVirgensEmPinturasAbstratas] = useState(false);
    const [showModalABuscaDoInconsciente, setShowModalABuscaDoInconsciente] = useState(false);
    const [showModalGirassol, setShowModalGirassol] = useState(false);
    const [showModalRelfexoes, setShowModalRelfexoes] = useState(false);
    const [showModalAVidaNaVelocidadeDaLuz, setShowModalAVidaNaVelocidadeDaLuz] = useState(false);

    function fecharModalOQueAbstraiAAbstracao(){
        setShowModalOQueAbstraiAAbstracao(false);
    }
    function fecharModalCurriculum(){
        setShowModalCurriculum(false);
    }
    function fecharModalEmocoesVirgensEmPinturasAbstratas(){
        setShowModalEmocoesVirgensEmPinturasAbstratas(false);
    }
    function fecharModalABuscaDoInconsciente(){
        setShowModalABuscaDoInconsciente(false);
    }
    function fecharModalGirassol(){
        setShowModalGirassol(false);
    }
    function fecharModalRelfexoes(){
        setShowModalRelfexoes(false);
    }
    function fecharModalAVidaNaVelocidadeDaLuz(){
        setShowModalAVidaNaVelocidadeDaLuz(false);
    }

    return(
        <section className="page-section" id="biografia">
            <div className="container relative">
                <h2 className="section-title font-alt mb-70 mb-sm-40">
                    Biografia
                </h2>
                <div className="row">
                    <div className="col-sm-4 mb-40">
                        <div className="text">
                            <h5 className="uppercase"><strong>O que abstrai a abstração</strong></h5>
                            No seu sentido mais amplo, toda a imagem já é uma abstração da realidade, no entanto a pintura abstrata exige que ultrapassemos etapas importantes...
                            <footer>
                                <Button variant="link" size="sm" className='fix-link botaoLink' onClick={() => setShowModalOQueAbstraiAAbstracao(true)}>
                                    Leia mais
                                </Button>
                            </footer>
                            <ModalOQueAbstraAAbstracao showModal={showModalOQueAbstraiAAbstracao} funcFechar={fecharModalOQueAbstraiAAbstracao}/>
                        </div>
                    </div>
                    <div className="col-sm-4 mb-40">
                        <div className="text">
                            <h5 className="uppercase"><strong>Curriculum</strong></h5>
                            <strong>1966</strong> - Rotary Club de Guarapuava - PR<br/>
                            <strong>1968</strong> - Edifício Nossa Senhora do Belém, Programa Cultural 150 anos de Guarapuava - PR<br/>
                            <strong>1974</strong> - Colônia...
                            <footer>
                                <Button variant="link" size="sm" className='fix-link botaoLink' onClick={() => setShowModalCurriculum(true)}>
                                    Leia mais
                                </Button>
                            </footer>
                            <ModalCurriculum showModal={showModalCurriculum} funcFechar={fecharModalCurriculum} />
                        </div>
                    </div>
                    <div className="col-sm-4 mb-40">
                        <div className="text">
                            <h5 className="uppercase"><strong>Amigos da escola</strong></h5>
                            Alunos aprendem matemática com obras de arte
                            <footer>
                                <a className="popup-youtube" href="https://www.youtube.com/watch?v=eOiSr-vHrac" rel="noreferrer" target="_blank">
                                    Assista
                                </a>
                            </footer>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 mb-40">
                        <div className="text">
                            <h5 className="uppercase"><strong>A vida na velocidade da luz</strong></h5>
                            Disse o poeta que a maior ilusão é ver as coisas como elas são. Pois o que reconhecemos “real” é apenas uma das possíveis leituras do mundo onde...
                            <footer>
                                <Button variant="link" size="sm" className='fix-link botaoLink' onClick={() => setShowModalAVidaNaVelocidadeDaLuz(true)}>
                                    Leia mais
                                </Button>
                            </footer>
                            <ModalAVidaNaVelocidadeDaLuz showModal={showModalAVidaNaVelocidadeDaLuz} funcFechar={fecharModalAVidaNaVelocidadeDaLuz}/>
                        </div>
                    </div>
                    <div className="col-sm-4 mb-40">
                        <div className="text">
                            <h5 className="uppercase"><strong>Emoções virgens em pinturas abstratas</strong></h5>
                            O artista não se faz. Ele nasce. Mas a obra adquire identidade própria só após constante trabalho e muitas pesquisas. Isto vale para todos os setores...
                            <footer>
                                <Button variant="link" size="sm" className='fix-link botaoLink' onClick={() => setShowModalEmocoesVirgensEmPinturasAbstratas(true)}>
                                    Leia mais
                                </Button>
                            </footer>
                            <ModalEmocoesVirgensEmPinturasAbstratas showModal={showModalEmocoesVirgensEmPinturasAbstratas} funcFechar={fecharModalEmocoesVirgensEmPinturasAbstratas}/>
                        </div>
                    </div>
                    <div className="col-sm-4 mb-40">
                        <div className="text">
                            <h5 className="uppercase"><strong>Celso Izidoro e a busca do inconsciente</strong></h5>
                            Abordagem da metodologia histórica crítica com o intuito de tecer relações vida e obra do artista a im de compreendermos que, por mais que existam...
                            <footer>
                                <Button variant="link" size="sm" className='fix-link botaoLink' onClick={() => setShowModalABuscaDoInconsciente(true)}>
                                    Leia mais
                                </Button>
                            </footer>
                            <ModalABuscaDoInconsciente showModal={showModalABuscaDoInconsciente} funcFechar={fecharModalABuscaDoInconsciente}/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6 mb-40">
                        <div className="text">
                            <h5 className="uppercase"><strong>Girasol</strong></h5>
                            Como uma explosão chegaram os girassóis<br/>
                            A simplicidade da flor me conquistou<br/>
                            Girassol<br/>
                            <footer>
                             <Button variant="link" size="sm" className='fix-link botaoLink' onClick={() => setShowModalGirassol(true)}>
                                    Leia mais
                                </Button>
                            </footer>
                            <ModalGirassol showModal={showModalGirassol} funcFechar={fecharModalGirassol}/>
                        </div>
                    </div>
                    <div className="col-sm-6 mb-40">
                        <div className="text">
                            <h5 className="uppercase"><strong>Reflexões</strong></h5>
                            "Izidoro transborda misticismo"<br/>
                            |Maria Helena de Morais|<br/>
                            "Um novo tempo"
                            <footer>
                                <Button variant="link" size="sm" className='fix-link botaoLink' onClick={() => setShowModalRelfexoes(true)}>
                                    Leia mais
                                </Button>
                            </footer>
                            <ModalRelfexoes  showModal={showModalRelfexoes} funcFechar={fecharModalRelfexoes}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}