import {Modal, Row, Col} from 'react-bootstrap';

function ModalOQueAbstraAAbstracao({showModal, funcFechar}){

    function fecharModal(){
        funcFechar();
    }

    return(
        <Modal show={showModal} onHide={fecharModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header className='fundo-header-modal' closeButton>
                <Modal.Title>O QUE ABSTRAI A ABSTRAÇÃO</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                    <p>No seu sentido mais amplo, toda a imagem já é uma abstração da realidade, no entanto a pintura abstrata exige que ultrapassemos etapas importantes do nosso conhecimento e exige de nós, espectador ou artista, uma reflexão sobre a prática pictural além do estudo profundo da história da arte e da cultura.</p>
                    <p>Celso Izidoro é um pintor autodidata, que como muitos outros pintores brasileiros, nasceu longe dos centros urbanos, inicialmente sem acesso às informações artísticas contemporâneas.</p>
                    <p>A arte é uma necessidade, o artista não escolhe data ou lugar, vem do seu impulso interior, da vontade incontrolável de se manifestar, o que Kandinsky falava como "necessidade interior": "a forma é a expressão exterior de um conteúdo interior", que pode se realizar tanto na figura como na abstração.</p>
                    <p>Celso se aproxima da arte muito jovem, faz pequenas esculturas de santos em pedra sabão, mas desenha a carvão nos muros, buscando as grandes formas, faltou-lhe aqui a orientação plástica e artística, pois a arte não é pura expressão, é também razão, e temos que disciplinar nossos impulso segundo um meio e um material. Isto ele teve que aprender sozinho, mas logo que se sentiu seguro, se pôs a ensinar – era a necessidade de criar um espaço no qual pudesse desenvolver sua vontade artística. Não só pela forma, mas principalmente pela história, nos faz lembrar de Manabu Mabe que em 1985 deu o seguinte depoimento:</p>
                    <p>"O que é arte? Qual a finalidade da minha pintura?”.</p>
                    <p>“Um certo dia pensei sobre tudo isto, e desde então, já se passaram mais de vinte anos.</p>
                    <p>Foi bom ter pensado, pois o lavrador tornou-se pintor e minha vida mudou.</p>
                    <p>Pescar lambaris e bagres em riachos do pasto, colher cocos e goiabas, brincar de correr atrás dos pássaros, são como poesias líricas inesquecíveis de minha infância.”</p>
                    <p>[....]</p>
                    <p>“Em 1958 explodiram as obras abstratas e as telas passaram a pulsar o sangue rubro de esperança e excitamento. Sim, as obras são o registro de minha vida. A partir de então minha vida foi toda talhada de obras.”</p>
                    <p>Quando Celso vem a Curitiba, em 1981, assimila pouco a pouco a obra de seus contemporâneos, Teca Sandrini, Jussara Age, Fernando Calderari, e outros.</p>
                    <p>Fez ilustrações e ainda, no início dos anos 80, começou a pintar telas figurativas, cujas figuras alongadas, ainda muito carregadas, demonstravam já sua relação mística entre o ser humano e o mundo. Ele vai tateando pelo que ele mesmo denomina de fases, mas que são mais especificamente a procura de caminhos para chegar a uma síntese; ele parte dos elementos da natureza, como o girassol, por exemplo, carregado de significados após os Girassóis de van Gogh, neles encontra o seu mundo fantástico, onírico. A seguir, geometriza as formas procurando essências, sai do desenho complexo em busca novamente da síntese e seu pincel também acompanha esta evolução.</p>
                    <p>No final dos anos 80, preso aos ideais místico, surge nele uma linguagem simbólica, de letras e signos, semelhantes às inscrições primitivas que lembram escritas mágicas ancestrais.</p>
                    <p>Aurélio Benitez afirmou que “há um certo clima bíblico em determinados quadros. Assim como há sinais que podem pertencer a uma linguagem mística, ...”. Mas estas obras demonstram sempre a sua vontade de síntese, de limpeza da superfície pictórica, do abandono do aglomerado de formas, quando tudo parece buscar a luz. Desde 1987 vem novamente tateando numa nova linguagem que ele mesmo chama abstrata.</p>
                    <p>A abstração é um trabalho de pesquisa formal. Celso jamais abandona a figura, ele sobrevive da sua arte, o que ainda é raro em nossos dias, por vezes é obrigado a recorrer à realidade objetiva dos retratos de personalidades para se manter economicamente, mas mesmo assim, com todas as dificuldades de se impor um programa estético, ele continua buscando a liberdade, seja ela no gesto, na matéria pictórica, nos signos ou no lirismo de suas telas. Abstração, sem, no entanto, abandonar por completo a relação com o figurativo.</p>
                    <p>Novamente morando em contato direto com a natureza, mas quase no centro de Curitiba, as abstrações de Celso Izidoro, no seu caráter decorativo, são deslumbramentos com as formas e as cores desta natureza misticamente emaranhadas entre si: há, pois, um lado mágico no girassol quando ele segue o sol, nos afirma ele. Sua obra é resultado de um aprendizado empírico, aprendeu fazendo no processo de tentativa de erro e acerto, muitas telas devem ter sido jogadas fora. Mas seu trabalho vem da intuição primitiva, própria do verdadeiro artista, sofrido e de uma imensa vontade de expressão.</p>
                    <p>A relação dos pintores com a espiritualidade não é um fato novo, esteve em Kandinsky, Mondrian e Malevitch, mas ainda se afirma em nossos dias, ainda esperamos utopicamente, buscar a desmaterialização para escapar ao peso de nosso planeta. A pintura de Celso Izidoro, de efeitos cromáticos e luminosos quer chegar no limite das coisas, da luz, da matéria, em busca do despojamento e de um ideal místico de encontro com o seu próprio universo interior.</p>
                    <p>Fernando A. F. Bini</p>
                    <p>Professor de História da Arte e Crítico de Arte,<br/>
                    membro da ABCA(Associação Brasileira dos críticos de arte)<br/>
                    e da AICA(Associação Internacional dos Críticos de Arte)</p>
                    <p>Março de 2006</p>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ModalOQueAbstraAAbstracao;