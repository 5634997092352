import YoutubeEmbed from '../../../YoutubeEmbed';
import fotoCelso1 from './../../images/celso/celso_2016_1.jpg';
import fotoCelso2 from './../../images/celso/celso_facebook_1.jpg';
import fotoCelso3 from './../../images/celso/celso_2016_2.jpg';

export default function SectionSobre(){
    return (
        <section className="page-section" id="sobre">
            <div className="container relative">
                <h2 className="section-title font-alt align-left mb-70 mb-sm-40">
                    Sobre Celso Izidoro
                </h2>
                <div className="section-text mb-50 mb-sm-20">
                    <div className="row">
                    
                        <div className="col-md-4">
                            <blockquote>
                                <p>
                                Celso Izidoro é um artista plástico brasileiro, residente em Curitiba Paraná, cujo seu trabalho vem sendo desenvolvido desde 1966.
                                </p>
                                <footer>
                                    <div className="local-scroll">
                                        <a href="#biografia">Saiba Mais</a>
                                    </div>
                                </footer>
                            </blockquote>
                        </div>
                        <div className="col-md-8 col-sm-6 mb-sm-50 mb-xs-30">
                            <div className="videoWrapper">
                                <YoutubeEmbed embedId="zs_0g49QwPE" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 mb-xs-30">
                        <div className="team-item">
                            <img src={fotoCelso1} alt="Celso Izidoro" />
                        </div>
                    </div>
                    <div className="col-sm-4 mb-xs-30">
                        <div className="team-item">
                            <img src={fotoCelso2} alt="Celso Izidoro" />
                        </div>
                    </div>
                    <div className="col-sm-4 mb-xs-30">
                        <div className="team-item">
                            <img src={fotoCelso3} alt="Celso Izidoro" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}