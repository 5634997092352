

import SectionHeader from './Components/SectionHeader';
import NavBar from './Components/NavBar'
import SectionBiografia from './Components/SectionBiografia';
import SectionSobre from './Components/SectionSobre';
import SectionLivro from './Components/SectionLivro';
import SectionGaleria from './Components/SectionGaleria';

import './style-fix.css';
import SectionTestemunhos from './Components/SectionTestemunhos';
import SectionContato from './Components/SectionContato';
import SectionFooter from './Components/SectionFooter';

function Original(){
    return (
        <div>
            <div className="page-loader">
                <div className="loader">Loading...</div>
            </div>
            <div className="page" id="top">
                <SectionHeader/>
                <NavBar/>
                <SectionSobre/>
                <SectionBiografia/>
                <SectionLivro/>
                <SectionGaleria/>
                <SectionTestemunhos/>
                <SectionContato/>
                <SectionFooter/>
            </div>
        </div>
    )
}

export default Original;