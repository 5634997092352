import {Modal, Row, Col} from 'react-bootstrap';

import	foto1	from './../../../../images/geleria/abstrato/Abstrato_22.jpg';
import	foto2	from './../../../../images/geleria/abstrato/Abstrato_23.jpg';
import	foto3	from './../../../../images/geleria/abstrato/Abstrato_24.jpg';
import	foto4	from './../../../../images/geleria/abstrato/Abstrato_25.jpg';
import	foto5	from './../../../../images/geleria/abstrato/Abstrato_26.jpg';
import	foto6	from './../../../../images/geleria/abstrato/Abstrato_27.jpg';
import	foto7	from './../../../../images/geleria/abstrato/Abstrato_28.jpg';
import	foto8	from './../../../../images/geleria/abstrato/Abstrato_29.jpg';

function ModalAbstrato({showModal, funcFechar}){

    const Galeria = [
        { src:	foto1	},
        { src:	foto2	},
        { src:	foto3	},
        { src:	foto4	},
        { src:	foto5	},
        { src:	foto6	},
        { src:	foto7	},
        { src:	foto8	}
    ];

    function fecharModal(){
        funcFechar();
    }

    return(
        <Modal show={showModal} onHide={fecharModal} fullscreen={true} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header className='fundo-header-modal' closeButton>
                <Modal.Title>Abstrato</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                {
                    Galeria.map((x, index) => {
                        return(
                            <Col md={4} lg={4} sm={6} key={index}>
                                <img src={x.src} alt="Celso Izidoro - Abstrato" className='imgGaleria'/>
                            </Col>
                        )
                    })
                }
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ModalAbstrato;