import {Modal, Row, Col} from 'react-bootstrap';

function ModalEmocoesVirgensEmPinturasAbstratas({showModal, funcFechar}){

    function fecharModal(){
        funcFechar();
    }

    return(
        <Modal show={showModal} onHide={fecharModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header className='fundo-header-modal' closeButton>
                <Modal.Title>EMOÇÕES VIRGENS EM PINTURAS ABSTRATAS</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <p>O artista não se faz. Ele nasce. Mas a obra adquire identidade própria só após constante trabalho e muitas pesquisas. Isto vale para todos os setores relacionados com a área criativa humana. Mas não é tão fácil aceitar–se essa afirmação, uma vez que a racionalidade humana pede prova sólida para aquilo que diz respeito às atividades de nossas vidas. E não existe prova para indicar que esta criança ou aquele adolescente vai ser um artista. Começa aí a difícil tarefa que a vida apresenta aos artistas. A própria sutileza da alma humana colabora para tornar ainda mais difícil a afirmação de que alguém vai ser isto ou aquilo durante a vida. É preciso conhecer muito bem as nuanças do ser humano durante a sua formação para identificar as suas tendências. Isso não é tarefa fácil. Mas também não é impossível</p>

                        <p>No caso do artista plástico Celso Izidoro, já aos oito anos se apresentavam os primeiro sinais de que a pintura se tornaria uma força dominante em sua vida. A essa idade, ao riscar os muros e as paredes de sua casa estavam ali surgindo os primeiros sinais de sua tendência. É verdade que até os dez ou onze anos, toda criança se delicia pintando e brincando com as cores. Mas isso acaba logo no início da adolescência. Se a tendência continuar, é sinal de que ali está um futuro artista.</p>

                        <p>É verdade que gostaríamos de afirmar que essa tendência é um privilégio. E não deixa de ser uma benção, porque ela só nasce com determinadas pessoas. Pode ter ou não origem genética. Mas o certo é que ela se origina num universo que ainda não é atingido pelo conhecimento do homem. Mas é bom esclarecer que o privilégio fica no mundo íntimo das pessoas. Fica na ilimitada visão de vida que se lhes abre. Fica na amplidão estética do seu mundo e na satisfação particular que sentem ao realizar uma obra que é só delas e de mais ninguém. O privilégio fica nisso porque nascer um artista é algo sofrido quando se trata de reconhecimento do público, do retorno financeiro, de pesquisas infindas que devem ser realizadas para se chegar a uma obra madura. Essa parte árdua, todos os bons pintores conhecem. É preciso maturidade intelectual para que se possa enfrentar isso. Pois Celso Izidoro é uma pessoa que ao atingir a adolescência, sentiu crescer dentro de si a vontade de manusear as tintas, conhecer os segredos da escultura, de falar por meio das cores e das formas. A essa altura já era possuído pelas artes plásticas. Ele entregou–se totalmente a esse chamado e começou a sua luta.</p>

                        <p>Já aos quinze anos pintava telas de grandes dimensões. Não gostava de lápis para traçar desenhos miúdos. Gostava de amplidão. Era a abstração que o chamava. No começo colocava insetos para se moverem nas tintas em cima das telas. As manchas que surgiam absorviam a mente do adolescente. Aos poucos seu intelecto foi amadurecendo e começou a buscar formas individuais, isto é, manchas surgidas do próprio eu, como criação particular. Durante bom tempo manchava as telas procurando formas que lhe agradassem. Nesse ínterim surgiram as implicações existenciais, próprias de pessoas sensíveis. É quando a arte se mistura com as vicissitudes da vida. Há então o entrevero das cores e formas com as emoções. Celso Izidoro, durante alguns anos, se manteve nesse diálogo. Foi quando começou a se indagar sobre o bem e o mal. Sobre as inquietações próprias do ser humano. Surgiam então em suas telas, grandes manchas com cores mais ou menos sombrias, como o preto e o cinza. O preto predominava nos espaços. Os verdes quase que amortizavam a frieza. Mas o clima escuro vencia. Essa fase durou anos. Já adulto, amadurecido, começaram a aflorar nas das telas gigantes luzes vibrantes. Era o branco aparecendo como dominador dos espaços. Era o amadurecimento intelectual, aliado às pesquisas com tintas, formas e a convicção de que a abstração era realmente o meio de expressão da arte cuja essência nascera com ele. Muitos anos de observação no mundo da pintura e da natureza lhe deram a certeza de que somente com as cores e as grandes manchas povoando as telas ele seria capaz de trazer à tona o seu mundo íntimo e tentar revelar aos outros uma realidade repleta de emoções, com toques de novidade individual.</p>

                        <p>Já adulto, no início dos anos 80, depois de ter dominado a ânsia de querer mostrar tudo o que fazia a todo o mundo, pronto para receber críticas a favor ou contra a sua arte, Celso Izidoro começou a produzir uma obra de linguagem abstrata, que não deixa de ser o reflexo de toda a sua vida mergulhada na difícil tarefa da criação.</p>

                        <p>Existe uma placidez não totalmente dominada no universo emocional das cores na arte de Celso Izidoro. Ela está refletida nas manchas brancas presentes em quase todas as telas recentes do pintor. É verdade que essa tonalidade não é a dominante, mas a sua soberania sobre as outras é indiscutível.</p>

                        <p>O berço original dessa nuança cromática está no âmago da alma do artista. Ele demorou anos em luta com a força das cores escuras, tendendo para o sombrio. Com o amadurecimento psicológico, emocional e vivencial, Celso Izidoro foi guindado pelo branco. Era o encontro com a calma espiritual, com a luz repleta de sinais de euforia. É por isso que nas últimas telas essa cor predomina. Mas é bom notar que a calma que o artista encontrou na vida não é algo plenamente resolvido. A placidez não está totalmente dominada nesta arte. É que a alma se recusa a aceitar uma paz sem retoques. Isso faz parte do inconsciente do pintor. Daí o branco pedir ajuda de outras cores. Por isso ele nunca está imaculado. Mesmo quando domina os espaços, uma camada mais pronunciada da mesma tinta aparece para sacudir a placidez total. Também é verdade que há uma diversidade cromática nas telas. Não podia ser diferente.</p>

                        <p>Esta arte é um produto de um mundo ligado aos trópicos, à natureza de presença forte e intrigante para a sensibilidade de artistas plásticos como Celso Izidoro. O que acontece é que o branco é presença constante. A variação cromática dá o tom da diversidade emocional própria de quem vê o sol na cara e o verde da vegetação a todo instante da vida. Esse amálgama dá uma vibração serena à arte deste pintor paranaense. A serenidade se apresenta por meio das formas quase geométricas presentes nas telas. Não existe convulsão na estrutura das formas. Há calma e serenidade. Abstração é cor e forma. E com esses elementos Celso Izidoro cria uma arte que penetra nas emoções ainda virgens do observador.</p>

                        <p>| Aurélio Benitez |</p>

                        <p>Jornalista, membro da ABCA (Associação Brasileira de Críticos de Arte) e do Jornal do Estado do Paraná, 1998.</p>

                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ModalEmocoesVirgensEmPinturasAbstratas;