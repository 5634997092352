import React, { useState } from 'react';
import {Button} from 'react-bootstrap';

import capaGaleriaTelasDisponiveis from './../../images/geleria/telasdisponiveis/DSC01055.jpg';
import capaGaleriaAtelier from './../../images/geleria/atelier/DSC01681.jpg';
import capaGaleriaFigurativo from './../../images/geleria/figurativo/foto19.jpg';
import capaGaleriaAbstrato from './../../images/geleria/abstrato/Abstrato_24.jpg';
import capaGaleriaAmigosEscola from './../../images/geleria/amigosdaescola/PA220073.jpg';
import capaGaleriaSimbologia from './../../images/geleria/simbologia/foto04.jpg';
import ModalAtelier from '../Modal/Galeria/Atelier';
import ModalTelasDisponiveis from '../Modal/Galeria/TelasDisponiveis';
import ModalFigurativo from '../Modal/Galeria/Figurativo';
import ModalAbstrato from '../Modal/Galeria/Abstrato';
import ModalAmigosDaEscola from '../Modal/Galeria/AmigosDaEscola';
import ModalSimbologia from '../Modal/Galeria/Simbologia';

function SectionGaleria(){

    const [showModalAtelier, setShowModalAtelier] = useState(false);
    const [showModalTelasDisponiveis, setShowModalTelasDisponiveis] = useState(false);
    const [showModalFigurativo, setShowModalFigurativo] = useState(false);
    const [showModalAbstrato, setShowModalAbstrato] = useState(false);
    const [showModalAmigosDaEscola, setShowModalAmigosDaEscola] = useState(false);
    const [showModalSimbologia, setShowModalSimbologia] = useState(false);

    function fecharModalAtelier(){
        setShowModalAtelier(false);
    }
    function fecharModalTelasDisponiveis(){
        setShowModalTelasDisponiveis(false);
    }
    function fecharModalFigurativo(){
        setShowModalFigurativo(false);
    }
    function fecharModalAbstrato(){
        setShowModalAbstrato(false);
    }
    function fecharModalAmigosDaEscola(){
        setShowModalAmigosDaEscola(false);
    }
    function fecharModalSimbologia(){
        setShowModalSimbologia(false);
    }

    return(
        <section className="page-section pb-0" id="galeria">
            {/* <div className="relative"/> */}
            <h2 className="section-title font-alt mb-70 mb-sm-40">
                Galeria
            </h2>
            <div className="container relative">
                <div className="row">
                    <center><h3>Quadros atuais</h3></center>
                </div>
                <div className="row multi-columns-row mb-30 mb-xs-10">
                    <div className="col-md-3 col-lg-3 mb-md-10"></div>
                    <div className="col-md-3 col-lg-3 mb-md-10">
                        <div className="post-prev-img">
                            <Button variant="link" size="sm" className='fix-link botaoLink' onClick={() => setShowModalAtelier(true)}>
                                <img src={capaGaleriaAtelier} alt="" />
                                <center>Atelier</center>
                                <center>Ver mais...</center>
                            </Button>
                        </div>
                        <ModalAtelier showModal={showModalAtelier} funcFechar={fecharModalAtelier}/>
                    </div>
                    <div className="col-md-3 col-lg-3 mb-md-10">
                        <div className="post-prev-img">
                            <Button variant="link" size="sm" className='fix-link botaoLink' onClick={() => setShowModalTelasDisponiveis(true)}>
                                <img src={capaGaleriaTelasDisponiveis} alt="" />
                                <center>Telas Disponíveis</center>
                                <center>Ver mais...</center>
                            </Button>
                        </div>
                        <ModalTelasDisponiveis showModal={showModalTelasDisponiveis} funcFechar={fecharModalTelasDisponiveis}/>
                    </div>
                    <div className="col-md-3 col-lg-3 mb-md-10"></div>
                </div>
                <div className="row">
                    <center><h3>Coleção Particular</h3></center>
                </div>
                <div className="row multi-columns-row mb-30 mb-xs-10">
                    <div className="col-md-3 col-lg-3 mb-md-10">
                        <div className="post-prev-img">
                            <Button variant="link" size="sm" className='fix-link botaoLink' onClick={() => setShowModalFigurativo(true)}>
                                <img src={capaGaleriaFigurativo} alt="" />
                                <center>Figurativo</center>
                                <center>Ver mais...</center>
                            </Button>
                            <ModalFigurativo showModal={showModalFigurativo} funcFechar={fecharModalFigurativo}/>
                        </div>
                    </div>
                    <div className="col-md-3 col-lg-3 mb-md-10">
                        <div className="post-prev-img">
                            <Button variant="link" size="sm" className='fix-link botaoLink' onClick={() => setShowModalAbstrato(true)}>
                                <img src={capaGaleriaAbstrato} alt="" />
                                <center>Abstrato</center>
                                <center>Ver mais...</center>
                            </Button>
                        </div>
                        <ModalAbstrato showModal={showModalAbstrato} funcFechar={fecharModalAbstrato}/>
                    </div>
                    <div className="col-md-3 col-lg-3 mb-md-10">
                        <div className="post-prev-img">
                            <Button variant="link" size="sm" className='fix-link botaoLink' onClick={() => setShowModalAmigosDaEscola(true)}>
                                <img src={capaGaleriaAmigosEscola} alt="" />
                                <center>Amigos da Escola - Projeto da Escola Universidade</center>
                                <center>Ver mais...</center>
                            </Button>
                        </div>
                        <ModalAmigosDaEscola showModal={showModalAmigosDaEscola} funcFechar={fecharModalAmigosDaEscola}/>
                    </div>
                    <div className="col-md-3 col-lg-3 mb-md-10">
                        <div className="post-prev-img">
                            <Button variant="link" size="sm" className='fix-link botaoLink' onClick={() => setShowModalSimbologia(true)}>
                            <img src={capaGaleriaSimbologia} alt="" />
                                <center>Simbologia</center>
                                <center>Ver mais...</center>
                            </Button>
                        </div>
                        <ModalSimbologia showModal={showModalSimbologia} funcFechar={fecharModalSimbologia}/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionGaleria;