import {Modal, Row, Col} from 'react-bootstrap';

import	foto1	from './../../../../images/geleria/figurativo/DSC01070.jpg';
import	foto2	from './../../../../images/geleria/figurativo/foto04.jpg';
import	foto3	from './../../../../images/geleria/figurativo/foto05.jpg';
import	foto4	from './../../../../images/geleria/figurativo/foto06.jpg';
import	foto5	from './../../../../images/geleria/figurativo/foto08.jpg';
import	foto6	from './../../../../images/geleria/figurativo/foto15.jpg';
import	foto7	from './../../../../images/geleria/figurativo/foto17.jpg';
import	foto8	from './../../../../images/geleria/figurativo/foto19.jpg';
import	foto9	from './../../../../images/geleria/figurativo/foto20.jpg';
import	foto10	from './../../../../images/geleria/figurativo/foto21.jpg';
import	foto11	from './../../../../images/geleria/figurativo/foto22.jpg';
import	foto12	from './../../../../images/geleria/figurativo/foto25.jpg';
import	foto13	from './../../../../images/geleria/figurativo/foto26.jpg';
import	foto14	from './../../../../images/geleria/figurativo/foto28.jpg';
import	foto15	from './../../../../images/geleria/figurativo/foto29.jpg';
import	foto16	from './../../../../images/geleria/figurativo/Foto30.jpg';
import	foto17	from './../../../../images/geleria/figurativo/Foto31.jpg';
import	foto18	from './../../../../images/geleria/figurativo/Foto32.jpg';
import	foto19	from './../../../../images/geleria/figurativo/Foto33.jpg';
import	foto20	from './../../../../images/geleria/figurativo/Foto34.jpg';
import	foto21	from './../../../../images/geleria/figurativo/Foto35.jpg';
import	foto22	from './../../../../images/geleria/figurativo/Foto36.jpg';
import	foto23	from './../../../../images/geleria/figurativo/Foto37.jpg';
import	foto24	from './../../../../images/geleria/figurativo/Foto39.jpg';
import	foto25	from './../../../../images/geleria/figurativo/Foto40.jpg';
import	foto26	from './../../../../images/geleria/figurativo/Foto41.jpg';

function ModalFigurativo({showModal, funcFechar}){

    const Galeria = [
        { src:	foto1	},
        { src:	foto2	},
        { src:	foto3	},
        { src:	foto4	},
        { src:	foto5	},
        { src:	foto6	},
        { src:	foto7	},
        { src:	foto8	},
        { src:	foto9	},
        { src:	foto10	},
        { src:	foto11	},
        { src:	foto12	},
        { src:	foto13	},
        { src:	foto14	},
        { src:	foto15	},
        { src:	foto16	},
        { src:	foto17	},
        { src:	foto18	},
        { src:	foto19	},
        { src:	foto20	},
        { src:	foto21	},
        { src:	foto22	},
        { src:	foto23	},
        { src:	foto24	},
        { src:	foto25	},
        { src:	foto26	}
    ];

    function fecharModal(){
        funcFechar();
    }

    return(
        <Modal show={showModal} onHide={fecharModal} fullscreen={true} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header className='fundo-header-modal' closeButton>
                <Modal.Title>Figurativo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                {
                    Galeria.map((x, index) => {
                        return(
                            <Col md={4} lg={4} sm={6} key={index}>
                                <img src={x.src} alt="Celso Izidoro - Figurativo" className='imgGaleria'/>
                            </Col>
                        )
                    })
                }
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ModalFigurativo;