import livro from './../../images/CapaLivro.png';
import imgCapa3 from './../../images/fwi/bg3.jpg';

export default function SectionLivro(){
    return(
        <section className="page-section pt-0 pb-0 banner-section bg-dark" id="livro" style={{ backgroundImage: `url(${imgCapa3})` }}>
            <div className="container relative">
                <div className="row">
                    <div className="col-sm-6">
                        <div className="mt-140 mt-lg-80 mb-140 mb-lg-80">
                            <div className="banner-content">
                                <div className="banner-decription">
                                    <strong><i>"... Na minha vida e na minha pintura, o equilíbrio, a natureza e a divindade estão presentes."</i></strong>
                                </div>
                                <footer>
                                    -- Celso izidoro
                                </footer>
                            </div>
                            <br/><br/><br/><br/>
                            <div className="local-scroll">
                                <a href="livro/viewer.html" className="btn btn-mod btn-w btn-medium btn-round" target="_blank">
                                    Clique para ler o livro
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 banner-image wow fadeInUp">
                        <img src={livro} alt="Livro Celso Izidoro" />
                    </div>
                </div>
            </div>
        </section>
    )
}