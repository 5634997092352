import {Modal, Row, Col} from 'react-bootstrap';

function ModalCurriculum({showModal, funcFechar}){

    function fecharModal(){
        funcFechar();
    }

    return(
        <Modal show={showModal} onHide={fecharModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header className='fundo-header-modal' closeButton>
                <Modal.Title>CURRICULUM</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <p><strong>PRINCIPAIS INDIVIDUAIS</strong></p>

                        <p>
                        1966 - Rotary Club de Guarapuava - PR<br/>
                        1968 - Edifício Nossa Senhora do Belém, Programa Cultural 150 anos de Guarapuava - PR<br/>
                        1974 - Colônia Entre Rios - Programa Cultural 30 anos de Colonização Alemã<br/>
                        1976 - Canadá Country Club, Foz do Iguaçu - PR<br/>
                        1978 - Atalaia Palace Hotel - Programa Cultural 160 anos de Guarapuava - PR<br/>
                        1980 - Teatro Guaíra - Conferência Bi-Distrital do Rotary Internacional, Curitiba - PR <br/>
                        1984 - Circulo Militar do Paraná - Jubileu de Ouro, Curitiba - PR<br/>
                        1985 - Esculturarte (Girassóis), Curitiba - PR<br/>
                        1986 - Cocaco Galeria de Arte - (Fase Dourada), Curitiba - PR<br/>
                        1987 - Centro Cultural Dr. Pedro Marini, Semana da Arte Uruguaiana Rio Grande do Sul, Uruguaiana - RS<br/>
                        1987 - Casa da Cultura de Florianópolis - Programa do Museu de Arte de Santa Catarina, Florianópolis - SC<br/>
                        1993 - Rua 24 Horas, Comemoração 300 Anos de Curitiba, Curitiba - PR<br/>
                        1999 - Emoções Virgens - Espaço Cultural Mokiti Okada, Curitiba - PR<br/>
                        2002 - Campos de Cor - Fundação Cultural de Blumenau – SC<br/>
                        2005 - Comemora 40 anos de pintura, realizando uma retrospectiva em seu ateliê, Curitiba – PR<br/>
                        2005 - Sentido da Cor – Itaipu Binacional, Curitiba - PR<br/>
                        2005 - Exposição revivendo a história de guarapuava, programa cultural 186 anos de guarapuava - PR - BR<br/>
                        </p>

                        <p><strong>PRINCIPAIS COLETIVAS</strong></p>

                        <p>
                        1970 - Menção Honrosa Em Pintura - Promoção Escola Prof. Amarilio, Guarapuava - PR<br/>
                        1984 - 40º Salão Paranaense, Curitiba - PR<br/>
                        1985 - 20º Prêmio Pirelli - “Pintura Jovem”
                        1985 - Salão Alcy Ramalho Filho de Artes Plásticas Curitiba- Pr<br/>
                        1986 - Sala Especial - 1ª Exposição de Arte do Circulo Militar do Paraná<br/>
                        1986 - Brasilian Contemporary Art. Exhibition, Hodality Painter, Juri’s Classification Grand Silver, Medal - Curtis Hixon - Convention Center Tampa - Flórida - USA<br/>
                        1987 - 43º Salão Paranaense - Museu de Arte Contemporânea, Curitiba - PR<br/>
                        1987 - 20º Salão de Belas Artes da Primavera - Clube Concórdia, Curitiba - PR<br/>
                        1987 - 1º Salão Nacional de Arte Religiosa - PUC- Pontifícia Universidade Católica do Paraná - PR<br/>
                        1987 - 43º Salão Paranaense, Curitiba - PR<br/>
                        1987 - 1ª Bienal Internacional (Anuário Latino Americano de Artes Plásticas), São Paulo - SP<br/>
                        1987 - Curitiba Arte 4, Curitiba - PR<br/>
                        1988 - 2ª Bienal de Arte Mística , Governador Valadares - MG<br/>
                        1988 - 44º Salão Paranaense, Curitiba - PR<br/>
                        1988 - Grande Medalha de Bronze - Coletiva Lions Clube de Curitiba<br/>
                        1988 - Three American Talents: Celso Izidoro (Brasil), Elizabeth Rocha Argentina), Libia (Spain)<br/>
                        1988 - LRC Gallery - New Jersey - U.S.A.<br/>
                        1988 - Único artista convidado a representar o Brasil juntamente com 15 Nações na P.C.C.C. Internacional Broadway Gallerie – Paterson, New Jersey - USA<br/>
                        1994 - Grandes Mestres de Arte - Galeria Maison Batel, Curitiba - PR<br/>
                        2000 - Exposição “A Brazilian Afair” - Hilton Kuala Lumpur - Kuala Lumpur - Malásia<br/>
                        </p>

                        <p><strong>ACERVO OFICIAL</strong></p>

                        <p>
                        - Museu de Arte de Santa Catarina - Florianópolis - SC<br/>
                        - Palácio Iguaçu - Curitiba - PR<br/>
                        - Museu Paranaense - Curitiba - PR<br/>
                        - Fundação Cultural de Blumenau - SC - BR<br/>
                        - Museu do Banestado de Curitiba - PR<br/>
                        - Casa da Cultura Dr. Pedro Marine - Uruguaiana - RS<br/>
                        - Minstead de Lodje - Minstead - Lynsdhurst - Hampshire - England <br/>
                        - Família Real da Malásia<br/>
                        - Teatro Municipal da Lapa –Pr.<br/>
                        - Funcação Mokiti Okada – Curitiba-Pr<br/>
                        - Embaixada do Brasil na França<br/>
                        - Cúria Metropolitana de Curitiba<br/>
                        - Museu Ucraniano Brasileiro Curitiba - PR<br/>
                        - Museu Municipal de Palmeira - PR<br/>
                        - Acervos particulares - dispostos em diversos estados brasileiros e em outros países, como: Portugal, Inglaterra, Alemanha, Estados Unidos, Japão, Áustria, Argentina, Suíça, Escócia, Irã, Arábia Saudita, Israel e Canadá.<br/>
                        </p>

                        <p><strong>CITAÇÃO</strong></p>

                        <p>
                        1980 - Referência em Planejamento Artes do Paraná / Indicador de Artes do Estado do Paraná - PR<br/>
                        1980 - Fatos do Meu Paraná, de Jamil Elias - PR<br/>
                        1981 - Personalidades do Ano - Como Artista Plástico do Oeste - PR<br/>
                        1986 - Das Sesmarias a Itaipu, autor Heitor F. Isidoro – PR<br/>
                        1987 - Membro do Júri do Salão Visão Nordeste 87 – Associação dos Artistas Plásticos do ABCD, São Paulo – SP.<br/>
                        1988 - Indicador Catarinense de Artes Plásticas, de Harry Laus<br/>
                        1988 - Artspeak - New York, crítica de Palmer Poroner - New York - E.U.A.<br/>
                        1989 - Júlio Louzada - Dicionário Artes Plásticas Brasil de 89, e, de 94 a 97<br/>
                        1989 - Cocaco 30 Anos - Eugênia Petrius - Curitiba - PR<br/>
                        1989 - Destaques 95 Melhores do Ano - Categoria Artes Plásticas - Jornal Diário Popular - Curitiba - PR<br/>
                        1989 - 50 Anos do Salão Paranaense de Belas Artes - Maria José Justino, doutora em História da Arte, da Universidade Federal do Paraná – PR<br/>
                        1989 - Recebe o Troféu Árvore do Sucesso - Revista Success - Curitiba - PR<br/>
                        1989 - Decor Year Book, Third Edition, Brasilian Interior Designers Year Book<br/>
                        1994 - A convite do governador Mário Pereira, retrata o primeiro presidente da província do Paraná Zacarias de Góis e Vasconcelos, para a Galeria dos Governadores do Palácio Iguaçu - Sede do Governo do Estado do Paraná<br/>
                        1996 - Destaque em Pintura - Melhores do ano de 1996, Diário do Paraná - PR<br/>
                        1996 - Retrata Helena Kolody - Homenagem especial, Poetisa Paranaense<br/>
                        1997 - Retrata o Papa João Paulo II, para a Cúria Metropolitana de Curitiba<br/>
                        1999 - Ilustrou o Calendário de 1999 do Sindicato dos Trabalhadores em Educação do 3º Grau Público do Paraná e Associação dos Servidores da Universidade Federal do Paraná - Curitiba - PR<br/>
                        1999 - Retrata Dom Pedro Fedalto para a Cúria Metropolitana - Homenagem aos 25 anos do Arcebispo da Arquidiocese de Curitiba - Curitiba - PR<br/>
                        1999 - Membro do Júri da 10ª Mostra de Artes Plásticas de Goioerê / Circuito Oficial de Salões da Secretaria de Estado da Cultura do Paraná – PR<br/>
                        2001 - Participa do projeto visão artística Brasil 500 anos - Associação profissional dos artistas plásticos do paraná e fuindação cultural de curitiba paraná -BR<br/>
                        2004 - Participa do calendário de 2004 poemas e imagens de Helena Kolody Curitiba -PR -BR<br/>
                        2005 - Participa do catálogo 2002 da fundação cultural de blumenau - SC - BR<br/>
                        2005 – Biografia de um Museu – Museu de Arte de Santa Catarina – Projeto Harry Laus, pesquisa e documentação Nancy T. Bortolin<br/>
                        2005 - Recebe o título de cidadão honorário do município de Guarapuava - PR<br/>
                        </p>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ModalCurriculum;