import imgCapa1 from './../../images/fwi/bg1.jpg';

import imgFacebook from './../../images/icons/facebook.png';
import imgInstagram from './../../images/icons/instagram.png';
import imgYoutube from './../../images/icons/youtube.png'
import imgTwitter from './../../images/icons/twitter.png'
import assinatura from './../../images/assinatura.png'

export default function SectionHeader(){
    return(
        <div className="bg-dark-alfa-30 bannerHeader" style={{ backgroundImage: `url(${imgCapa1})` }} id="home">
            <div className="js-height-full">
                <div className="home-content">
                    <div className="home-text">
                        <h1 className="hs-line-12 font-alt mb-80 mb-xs-30 mt-70 mt-sm-0">
                            Celso Izidoro
                        </h1>
                        <h1 className="hs-line-3 font-alt mb-80 mb-xs-30 mt-70 mt-sm-0">
                            <a href="https://www.facebook.com/celsoizidoro.izidoro?fref=ts" target="_blank" rel="noreferrer">
                                <img src={imgFacebook} alt="Celso Izidoro Facebook"/>
                            </a>
                            <a href="https://www.instagram.com/celsoizidoro/?hl=pt-br" target="_blank" rel="noreferrer">
                                <img src={imgInstagram} alt="Celso Izidoro Instagram"/>
                            </a>
                            <a href="https://www.youtube.com/channel/UCyZszDgfVNQECR8SJAZGKng" target="_blank" rel="noreferrer">
                                <img src={imgYoutube} alt="Celso Izidoro Youtube"/>
                            </a>
                            <a href="https://twitter.com/Celsoizidoro" target="_blank" rel="noreferrer">
                                <img src={imgTwitter} alt="Celso Izidoro Twitter"/>
                            </a>
                        </h1>
                        <div className='assinatura'>
                            <img src={assinatura} alt="Celso Izidoro"/>
                        </div>
                    </div>
                </div>
                <div className="local-scroll">
                    <a href="#sobre" className="scroll-down">
                        <i className="fa fa-angle-down scroll-down-icon"></i>
                    </a>
                </div>
            </div>
        </div>
    )
}