export default function SectionContato(){
    return (
        <section className="page-section" id="contato">
            <div className="container relative">
                <h2 className="section-title font-alt mb-70 mb-sm-40">
                    Contato
                </h2>
                <div className="row mb-60 mb-xs-40">
                    <div className="col-md-12 col-md-offset-2">
                        <div className="row">
                            <div className="col-sm-6 col-lg-4 pt-20 pb-20 pb-xs-0">
                                <div className="contact-item">
                                    <div className="ci-icon">
                                        <i className="fa fa-phone"></i>
                                    </div>
                                    <div className="ci-title font-alt">
                                        Telefone
                                    </div>
                                    <div className="ci-text">
                                        +55 41 9125-4050
                                    </div>
                                    <div className="ci-text">
                                        +55 41 3274-0195
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 pt-20 pb-20 pb-xs-0">
                                <div className="contact-item">
                                    <div className="ci-icon">
                                        <i className="fa fa-map-marker"></i>
                                    </div>
                                    <div className="ci-title font-alt">
                                        Endereço
                                    </div>
                                    <div className="ci-text">
                                        Rua Renato Polatti, Nº 3492- Campo Comprido, Curitiba - PR
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 pt-20 pb-20 pb-xs-0">
                                <div className="contact-item">
                                    <div className="ci-icon">
                                        <i className="fa fa-envelope"></i>
                                    </div>
                                    <div className="ci-title font-alt">
                                        Email
                                    </div>
                                    <div className="ci-text">
                                        celso.francisco.izidoro@gmail.com
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}